export default [
  {
    title: "Kastamonu Üniversitesi",
    year: "  2015-2017",
    description: "Bilgisayar Programcılığı",
  },
  {
    title: "Beylikdüzü Çok Programlı Meslek Lisesi",
    year: "2011-2015",
    description: "Web Tasarım ve Programlama",
  },
];
