export default [
  {
    title: "Smartiks Yazlım AŞ ",
    year: "05-2022 - Devam",
    description: " Frontend Developer",

  },
  {
    title: "NDK Psikolojik Danışmanlık ",
    year: "01-2022 02-2022",
    description: " React ile geliştirilen bir web sitesi ",
    link: "http://ndkdanismanlik.com/",
  },
  {
    title: "Efor Uygulaması  ",
    year: "Yapım Aşamasında",
    description:
      "Geliştiricilerin yapılan işin süresini ve süresini takip etmelerini sağlayan yazılım. ",
    link: "",
  },

  {
    title: "CRM  ",
    year: "Yapım Aşamasında",
    description:
      "Firmaların stok ve ilgili işlerini takip edebilecekleri yazılımlar. Yapım aşamasında.\n. ",
    link: "",
  },
];
